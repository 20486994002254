import { DLBPayAuthorization, DLBWXPay, GFWXPay, GFZFBPay } from '@/api/modules/pay'
import { DLBZFBPayProductQr,DLBWXPayProductQr,DLBPayAuthorizationProductQr} from '@/api/modules/productQr'
import WXPAY from '@/utils/WXPAY'
import { Toast } from 'vant'
import router from '@/router'

export const handlePayment = async (paymentType, paymentDetails) => {
  try {
    let paymentResult = null
    switch (paymentType) {
      case 'GFWX':
        // 处理官方微信支付逻辑
        paymentResult = await GFWXPay(paymentDetails)
        WXPAY(paymentResult.data.data, async (payResult) => {
          //alert(payResult.err_msg)
          if (payResult.err_msg === 'get_brand_wcpay_request:ok') {
            //Toast.success("支付成功");
            //执行分享后续步骤  跳到支付成功页面  带上订单id
            // await this.$router.push({
            //   path: '/pay/success',
            //   query: {
            //     orderId: paymentResult.data.data.orderId
            //   }
            // })
            // 构建要导航到的 URL
            // 使用 window.location.href 进行页面导航
            window.onpageshow = null
            window.onpageshow = function (event) {
              window.location.reload()
            }
            window.location.href = `/pay/success?orderId=${paymentResult.data.data.orderId}`
            // await router.push({
            //   path: '/pay/success',
            //   query: {
            //     orderId: paymentResult.data.data.orderId
            //   }
            // });
          } else if (payResult.err_msg === 'get_brand_wcpay_request:cancel') {
            Toast.fail('支付取消')
          } else if (payResult.err_msg === 'get_brand_wcpay_request:fail') {
            Toast('支付出错')
          }
        })
        break
      case 'DLBWX':
        // 处理哆啦宝微信支付逻辑
        paymentResult = await DLBWXPay(paymentDetails)
        WXPAY(paymentResult.data.data, async (payResult) => {
          if (payResult.err_msg === 'get_brand_wcpay_request:ok') {
            Toast.success('支付成功')
            //执行分享后续步骤  跳到支付成功页面  带上订单id
          } else if (payResult.err_msg === 'get_brand_wcpay_request:cancel') {
            Toast.fail('支付取消')
          } else if (payResult.err_msg === 'get_brand_wcpay_request:fail') {
            Toast('支付出错')
          }
        })
        break
      case 'GFALIPAY':
        // 处理官方支付宝支付逻辑
        paymentResult = await GFZFBPay(paymentDetails)

        // const div = document.createElement("div");
        // div.innerHTML = paymentResult.data; //res.data是返回的表单
        // document.body.appendChild(div);
        // document.forms.punchout_form.submit();
        const div = document.createElement('formdiv')
        div.innerHTML = paymentResult.data
        document.body.appendChild(div)
        document.forms[0].setAttribute('target', '_self')
        document.forms[0].submit()
        div.remove()
        break
      case 'DLBALIPAY':
        // 处理哆啦宝支付宝支付逻辑
        paymentResult = await DLBPayAuthorization(paymentDetails)
        window.location.replace(paymentResult.data.data)
        break
      default:
        // 默认处理方式
        // 处理官方微信支付逻辑
        break
    }
    return paymentResult
  } catch (error) {
    // 处理异常
    console.error('Payment error:', error)
    throw new Error('Payment failed.')
  }
}


export const handleProductQrPayment = async (paymentType, paymentDetails) => {
  try {
    let paymentResult = null
    switch (paymentType) {
      case 'GFWX':
        // 处理官方微信支付逻辑
        paymentResult = await GFWXPay(paymentDetails)
        WXPAY(paymentResult.data.data, async (payResult) => {
          //alert(payResult.err_msg)
          if (payResult.err_msg === 'get_brand_wcpay_request:ok') {
            Toast.success('支付成功')
          } else if (payResult.err_msg === 'get_brand_wcpay_request:cancel') {
            Toast.fail('支付取消')
          } else if (payResult.err_msg === 'get_brand_wcpay_request:fail') {
            Toast('支付出错')
          }
        })
        break
      case 'DLBWX':
        // 处理哆啦宝微信支付逻辑
        paymentResult = await DLBWXPayProductQr(paymentDetails)
        WXPAY(paymentResult.data.data, async (payResult) => {
          if (payResult.err_msg === 'get_brand_wcpay_request:ok') {
            Toast.success('支付成功')
          } else if (payResult.err_msg === 'get_brand_wcpay_request:cancel') {
            Toast.fail('支付取消')
          } else if (payResult.err_msg === 'get_brand_wcpay_request:fail') {
            Toast('支付出错')
          }
        })
        break
      case 'GFALIPAY':
        // 处理官方支付宝支付逻辑
        paymentResult = await GFZFBPay(paymentDetails)
        // const div = document.createElement("div");
        // div.innerHTML = paymentResult.data; //res.data是返回的表单
        // document.body.appendChild(div);
        // document.forms.punchout_form.submit();
        const div = document.createElement('formdiv')
        div.innerHTML = paymentResult.data
        document.body.appendChild(div)
        document.forms[0].setAttribute('target', '_self')
        document.forms[0].submit()
        div.remove()
        break
      case 'DLBALIPAY':
        // 处理哆啦宝支付宝支付逻辑
        paymentResult = await DLBPayAuthorizationProductQr(paymentDetails)
        window.location.replace(paymentResult.data.data)
        break
      default:
        // 默认处理方式
        break
    }
    return paymentResult
  } catch (error) {
    // 处理异常
    console.error('Payment error:', error)
    throw new Error('Payment failed.')
  }
}
